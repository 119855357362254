body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  display: flex;
  flex-direction: column;
  display: flex;
  flex-direction: column;
  background-color: #f8f9fa;


  .fullcontainer {
    text-align: -webkit-center;
    display: flex;
    flex-direction: column;
    text-align: justify;
  }
}

a {
  text-decoration: none;
}

.Container-header-mobile{
  display: none;
}


.Container-header{
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  position: fixed;
  top: 0;
  width: 100%;
  color: #282a2d;
  align-items: center;
  height: 125px;
}

.Container-Header-Topic{
  margin-bottom: 100px;
}

.Container-Header-Topic h4{
  font-size: 45px;
}



.header-option{
  display: flex;
  flex-direction: row;
  position: inherit;
  /* justify-content: end; */
  align-self: end;
}


.header-option h4{
  font-size: 30px;
  font-weight: 600;
  margin-left: 50px;
  margin-top: 50px;
  margin-bottom: 25px;
}
.header-option  a{
  color: #282a2d;
}

.menu-option a{
  color: #282a2d;
}



.row{
  width: 100%;
}

.Container-links{
  display: flex;
  flex-direction: row;
  justify-content: end;
}

.Container-links-mobile{
  display: flex;
  flex-direction: row;
  justify-content: end;
}

.link-logo {
  align-self: end;
  margin-top: 50px;
  margin-bottom: 25px;
  align-self: end;
  margin-right: 25px;
}

.link-logo img{
  margin-left: 15px;
  height: 25px;
}

.Container-Features{
  margin-top: 275px;
  margin-bottom: 50px;
  margin-left: 10%;
  margin-right: 2%;
}

.Container-Quotes{
  margin-bottom: 15px;
  text-align: left;
}

.Container-Quotes p{
  font-weight: 700;
  font-size: 45px;
}

.Footer-Quotes p{
  font-weight: 700;
  font-size: 60px;
}

.Footer-Quotes span{
  font-size: 45px;
  margin-bottom: 100px;
  text-align: left;
}


.bold{
  color: #215bea;
}


.Container-Topic-wrapper{
  display: flex;
  flex-direction: row;
  margin-right: 10%;
  margin-left: 10%;
  max-width: 90%;

}

.Container-Topic-wrapper{
  margin: 0px;
  width: 100%;
}

.Container-Topic{
  min-width: 300px;
  max-width: 500px;
  margin-bottom: 50px;

}

.Container-Topic h6{
  color: #215bea;
  margin-bottom: 50px;
  font-weight: 600;
  font-size: 30px;
}

.Container-Topic p{
  margin-bottom: 30px;
  text-align: left;
  font-size: 30px;
  margin-bottom: 100px;
  min-height: 180px;
}




.topic-img{
  
  min-width: 250px;
  max-width: 450px;
}

.topic-img img{
  width: 500px;
 
}

.Container-Topic-row{
  width: 100%!important;
  /* margin-right: 5%;
  margin-left: 5%; */
}

.Container-Mission{
  display: flex;
  flex-direction: column;
  background-color: #d3dae2;
  padding-bottom: 100px;
}

.App-logo{
  margin-top: 50px;
  align-self: end;
  margin-bottom: 25px;
  padding-left: 100px;
}

.App-logo img{
  display: block;
  width: 100px;
  
}

.menuBottom{
  align-self: end;
  margin-bottom: 10px;
  align-self: end;
}

.menuBottom img{
  width: 30px;
}

.Container-Mission-Itens-wrapper {
  margin-bottom: 150px;
}

.Container-Mission-wrapper {
  margin-top: 100px;
  margin-left: 10%;
  margin-right: 10%;
  width: 80vw;
}

.rigth{
  right: 0px !important;
}

.rigth img{
    float: right;
    height: 450px;
    margin-top: -770px;
    margin-bottom: 100px;
}

.items-wrapper{
  display: flex;
  flex-direction: column;
}

.Mission-item-wraper{
  display: flex;
  flex-direction: row;
  min-width: 300px;
}

.Mission-item-wraper-container{
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  
}

.Mission-item-wraper-container h3{
  color: #215bea;
  max-width: 430px;
  text-align: left;
  font-size: 60px;
  margin-bottom: 100px;
}

.Mission-item-wraper-container span{
  max-width: 430px;
  text-align: left;
  font-size: 30px;

}

.Mission-item{
  width: 70px;
  margin-bottom: 25px;
}

.Mission-item img{
  height: 150px;
}

.Mission-header{
  margin-bottom: 100px;
}

.Mission-header h4{
  font-weight: 600;
  font-size: 45px;
}

.Mission-footer{
  margin-top: 30px;

}

.Mission-footer h5{
  font-weight: 500;
  color: #215bea;
  text-align: left;
  font-size: 45px;
  margin-bottom: 25px;
}

.Mission-footer span{
  color: #215bea;
  text-align: left;
  font-size: 30px;
}

.Container-footer{
  margin-top: 100px;
  margin-bottom: 100px;
  margin-right: 10%;
  margin-left: 10%;
  
}

.Footer-Quotes {
  margin-bottom: 100px;
}

.menuContainer{
  display: none;
  background-color: #ffffff;
  width: 100vw;
  height: 120vh;
  position: absolute;
  z-index: 10;
  right: 0;
}

.menu-option{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 150px;
  margin-bottom: 100px ;
}

.menu-option h4{
  margin-bottom: 35px;
}

.display {
  display: block;
}

.menu-option-link {
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu-option-link img {

  height: 35px;
}

.menu-wrapper {
  display: flex;
  flex-direction: row;
}

.Container-Team{
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-bottom: 200px;
  }
  .Container-Team h4{
    font-size: 45px;
    font-weight: 600;
  }
  
  
  .teamIcon{
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 250px;
    text-align: left;
    margin-bottom: 50px;

}

.teamIcon p{
  font-size: 35px;
}

.teamIcon img{
  width: 300px;
  margin-bottom: 25px;
}

.teamIcon span{
  font-size: 25px;
  font-weight: 500;
}

.app-container{
  display: flex;
  flex-direction: row;
  height: 150px;
}

.App-store{
 max-width : 250px;
 margin: -50px 15px -50px 15px;
}

@media (max-width: 1900px){

  .Container-Quotes p {
    font-size: 35px;
  }

  .Container-Header-Topic h4 {
    font-size: 35px;
  }

  .Container-Topic h6 {
    font-size: 25px;
  }

  .Container-Topic p {
    font-size: 20px;
  }

 
  .Container-Topic{
    max-width: 360px;
  }

  .topic-img img {
    max-width: 200px;
  }

  .Mission-header h4 {
    font-size: 35px;
  }

  .Mission-item img {
    height: 80px;
  }

  .Mission-item-wraper-container h3{
    font-size: 35px;
  }

  .Mission-item-wraper-container span {
    max-width: 430px;
    font-size: 30px;
  }

  .Mission-item-wraper-container span {
    font-size: 20px;
    max-width: 420px;
  }

  .rigth img {
    float: right;
    height: 250px;
    margin-top: -640px;
    margin-bottom: 100px;
  }

  .Mission-footer h5 {
    font-size: 28px;
  }

  .Mission-footer span {
    font-size: 18px;
  }

  .Footer-Quotes span {
    font-size: 30px;
  }



}


@media (max-width: 1440px){

  .Container-header{
    height: 100px;
  }

  .header-option h4 {
    font-size: 25px;
  }
 
  .Container-Quotes p {
    font-size : 27px;
  }

  .Container-Header-Topic h4 {
    font-size: 40px;
  }
  .Container-Topic h6{
    font-size: 23px;
  }

  .Container-Topic {
    min-width: 260px;
    max-width: 500px;
    
  }

  .Container-Topic p {
    font-size: 20px;
  }

  .topic-img img {
    width: 250px;
  }

  .Mission-header h4{
    font-size: 40px;
  }

  .Mission-item img {
    height: 100px;
  }

  .Mission-item-wraper-container h3{
    font-size: 40px;
  }

  .Mission-item-wraper-container span{
    font-size: 20px;
  }
  
  .rigth img{
    height: 320px;
    margin-top: -660px;
  }

  .Mission-footer h5{
    font-size: 35px;
  }

  .Mission-footer span {
    font-size: 25px;
  }
  .Container-Team {
    margin-bottom: 150px;
  }

  .teamIcon p{
    font-size: 25px;
  }
  
  .teamIcon img{
    width: 200px;
  }
  .teamIcon span{
    font-size: 22px;
  }
}

@media only screen and  (max-width: 1024px){

  .App-logo {
    padding-left: 50px;
  }
  .header-option h4 {
    margin-left: 50px;
  }

  .header-option h4 {
    font-size: 20px;
  }

  .Container-Quotes p {
    font-size: 20px;
  }

  .Container-Header-Topic h4 {
    font-size: 35px;
  }

  .Container-Topic h6 {
    font-size: 22px;
  }

  .Container-Topic p {
    font-size: 15px;
    width: 250px;
  }

  .Mission-header h4{
    font-size: 35px;
  }

  .Mission-header{
    margin-bottom: 80px;
  }

  .Mission-item img {
    height: 75px;
  }

  .Mission-item-wraper-container {
    min-width: 390px;
  }

  .Mission-item-wraper-container h3 {
    font-size: 25px;
    margin-bottom: 50px;
  }

  .Mission-item-wraper-container span {
    font-size: 15px;
  }

  .rigth img {
    height: 250px;
    margin-top: -530px;
  }

  .Container-Mission-Itens-wrapper {
    margin-bottom: 50px;  
  }
  .Mission-footer h5 {
    font-size: 30px;
  }

  .Mission-footer span {
    font-size: 20px;
  }
  .Footer-Quotes {
    margin-bottom: 50px
  }

  .Footer-Quotes span {
    font-size: 20px;
  }

  
  .Container-Team {
    margin-bottom: 100px;
  }

  .teamIcon p{
    font-size: 20px;
  }
  
  .teamIcon img{
    width: 150px;
  }

  .teamIcon span{
    font-size: 16px;
  }

  .app-container{
    height: 100px;
  }
  
  .Container-footer{
    margin-top: 60px;
    
  }

}


@media only screen and  (max-width: 768px){

  .App-logo{
    padding-left: 50px
  }
  
  .App-logo img{
    width: 70px;
  }

  .header-option h4 {
    margin-left: 20px;
  }

  .header-option{
    justify-content: end;
  }

  .link-logo {
    margin-right: 5px;
  }

  .Container-Topic {
    min-width: 290px;
  }

  .Mission-item-wraper-container {
    min-width: 295px;
  }

  .Container-Quotes p {
    font-size: 15px;
  }


  .teamIcon p{
    font-size: 15px;
  }
  
  .teamIcon img{
    width: 150px;
  }

  .App-store{
    height: 150px;
  
  }


}

@media only screen and (max-width: 600px){
  .Container-header{
    display: none;
  }

  .Container-header-mobile{
    display:flex;
    position: fixed;
    background-color: #ffffff;
    flex-direction: row;
    top: 0;
    width: 100%;
    color: #282a2d;
    align-items: center;
    height: 80px;
  }

  .App-logo {
    margin-top: 50px;
    align-self: center;
  }
  .menuBottom img {
    width: 30px;
    align-self: end;
    margin-top: 50px;
    margin-bottom: 25px;
    margin-right: 50px;
  }

  .Container-Features {
    margin-top: 150px;
  }
  .topic-img img {
      width: 200px;
  }

  .Container-Topic {
    min-width: 250px;
  }
  .Container-Topic-wrapper{
    max-width: 100%;
  }

  .Container-Topic h6 {
    font-size: 20px;
}

  .Container-Topic p {
    font-size: 15px;
    width: 220px;
    margin-bottom: 50px;
  }
  .Mission-item img {
    height: 60px;
  }

  .Mission-item-wraper-container {
    min-width: 400px;
  }

  .Container-Quotes p {
    font-size: 22px;
  }
  .Container-Quotes p {
    font-size: 15px;
  }

  .teamIcon{
    margin-left: 30%;
  }
  
}


@media only screen and  (max-width: 425px){

  .App-logo {
    margin-top: 30px;
  
  }
  .menuBottom img {
    margin-right: 25px;
  }

  .Container-Quotes p {
    font-size: 15px;
    max-width: 350px;
  }
  
  .Container-Header-Topic {
    margin-bottom: 50px;
    min-width: 250px;
  }
  
  .Container-Topic h6{
    margin-bottom: 25px;
  }
  
  .Container-Topic p{
    min-width: 350px;
  }
  
  .topic-img {
    margin-left: 15%;
  }
  .topic-img img{
    width: 250px;
  }

  .Mission-item-wraper-container {
    min-width: 350px;
    

  }

  .Mission-item-wraper-container span {
    max-width: 300px;
  }

  .rigth img {
    height: 150px;
    margin-top: -350px;
  }
  
  .Mission-footer h5 {
    font-size: 25px;
  }

  .teamIcon{
    margin-left: 15%;
  }

  .app-container{
    display: flex;
    flex-direction: column;
    height: 150px;
  }

  .App-store{
    margin: -40px 15px -40px 15px;
   }

}

@media only screen and  (max-width: 375px){
  .Container-Topic p {
    min-width: 250px;
  }

  .topic-img img {
    width: 200px;
  }

  .Mission-item-wraper-container h3 {
    max-width: 250px;
  }

  .Mission-item-wraper-container {
    min-width: 250px;
}

  .Mission-item-wraper-container span {
    max-width: 250px;
}
  
.teamIcon{
  margin-left: 10%;
}

}

@media only screen and  (max-width: 320px){
  
  .Container-Topic p {
    min-width: 250px;
  }

  .topic-img img {
    width: 200px;
  }

  .Mission-item-wraper-container h3 {
    max-width: 250px;
  }

  .Mission-item-wraper-container {
    min-width: 250px;
}

  .Mission-item-wraper-container span {
    max-width: 250px;
}

}

@media only screen and (min-device-width: 480px) and (max-device-width: 900px) and (orientation: landscape) {

  .header-option h4{
    font-size: 18px;

  }
}









